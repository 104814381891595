import { gql } from "@apollo/client";
import { userAddressSmallFragment } from "./userAddressSmallFragment";
import { giftCardOrderItemFragment } from "./giftCardOrderItemFragment";
import { adminNoteFragment } from "./adminNoteFragment";
import { giftCardFragment } from "./giftCardFragment";

export const giftCardOrderFragment = gql`
    fragment GiftCardOrder on GiftCardOrder {
        id
        code
        created_at
        contact_fullname
        email
        phone_number
        user {
            email
        }
        status {
            id
            title
        }
        billing_address {
            ...UserAddressSmall
        }
        payment_method {
            id
            name
        }
        grand_total
        payment {
            status {
                id
                title
            }
            transaction_latest {
                id
                anum
            }
            amount_paid
            amount_refunded
        }
        admin_notes {
            ...AdminNote
        }
        items {
            ...GiftCardOrderItem
        }
        invoice_status {
            title
        }
        invoices {
            invoice_number
            url
        }
        can_be_deleted
        user_agent_type
        gift_cards {
            ...GiftCard
        }
        payment {
            status {
                title
            }
        }
    }
    ${adminNoteFragment}
    ${userAddressSmallFragment}
    ${giftCardOrderItemFragment}
    ${giftCardFragment}
`;
