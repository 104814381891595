import { adminMeQuery } from "./admin/queries/adminMeQuery";
import { adminContentsQuery } from "./admin/queries/adminContentsQuery";
import { listAdminsQuery } from "./admin/queries/listAdminsQuery";
import { getAdminQuery } from "./admin/queries/getAdminQuery";
import { getAdminContentQuery } from "./admin/queries/getAdminContentQuery";
import { getCategoriesForSelectQuery } from "./admin/queries/getCategoriesForSelect";
import { getDocumentsForModalQuery } from "./admin/queries/getDocumentsForModalQuery";
import { documentsQuery } from "./admin/queries/documentsQuery";
import { categoriesQuery } from "./admin/queries/categoriesQuery";
import { getCategoryQuery } from "./admin/queries/getCategory";
import { galleriesQuery } from "./admin/queries/galleriesQuery";
import { getGalleryQuery } from "./admin/queries/getGalleryQuery";
import { getGalleryImagesQuery } from "./admin/queries/getGalleryImagesQuery";
import { listProductBrandsQuery } from "./admin/queries/listProductBrandsQuery";
import { getProductBrandByIdQuery } from "./admin/queries/getProductBrandByIdQuery";
import { listProductsQuery } from "./admin/queries/listProductsQuery";
import { listProductCategoriesByIdQuery } from "./admin/queries/listProductCategoriesByIdQuery";
import { getProductByIdQuery } from "./admin/queries/getProductByIdQuery";
import { listProductParamStructuresQuery } from "./admin/queries/listProductParamStructuresQuery";
import { listProductCategorySelectItemsQuery } from "./admin/queries/listProductCategorySelectItemsQuery";
import { listProductListsQuery } from "./admin/queries/listProductListsQuery";
import { listBadgesQuery } from "./admin/queries/listBadgesQuery";
import { listBadgeByIdQuery } from "./admin/queries/listBadgeByIdQuery";
import { listProductParamSelectItemsQuery } from "./admin/queries/listProductParamSelectItemsQuery";
import { getProductListByIdQuery } from "./admin/queries/getProductListByIdQuery";
import { listRolesQuery } from "./admin/queries/listRolesQuery";
import { getRoleByIdQuery } from "./admin/queries/getRoleByIdQuery";
import { listPermissionsQuery } from "./admin/queries/listPermissionsQuery";
import { listProductFilterParamsQuery } from "./admin/queries/listProductFilterParamsQuery";
import { listClpePromotionSelectItemsQuery } from "./admin/queries/listClpePromotionSelectItemsQuery";
import { listProductBrandSelectItemsQuery } from "./admin/queries/listProductBrandSelectItemsQuery";
import { listBannersQuery } from "./admin/queries/listBannersQuery";
import { getBannerByIdQuery } from "./admin/queries/getBannerByIdQuery";
import { listUsersQuery } from "./admin/queries/listUsersQuery";
import { getUserByIdQuery } from "./admin/queries/getUserByIdQuery";
import { listUserOrdersQuery } from "./admin/queries/listUserOrdersQuery";
import { getPersonQuery } from "./admin/queries/getPersonQuery";
import { personsQuery } from "./admin/queries/personsQuery";
import { getBenefitQuery } from "./admin/queries/getBenefitQuery";
import { departmentsQuery } from "./admin/queries/departmentsQuery";
import { getDepartmentQuery } from "./admin/queries/getDepartmentQuery";
import { getDivisionQuery } from "./admin/queries/getDivisionQuery";
import { divisionsQuery } from "./admin/queries/divisionsQuery";
import { getSuccessStoryQuery } from "./admin/queries/getSuccessStoryQuery";
import { successStoriesQuery } from "./admin/queries/successStories";
import { redirectionsQuery } from "./admin/queries/redirectionsQuery";
import { getRedirectionQuery } from "./admin/queries/getRedirectionQuery";
import { benefitsQuery } from "./admin/queries/benefitsQuery";
import { listShippingMethodSelectItemsQuery } from "./admin/queries/listShippingMethodSelectItemsQuery";
import { listPaymentMethodSelectItemsQuery } from "./admin/queries/listPaymentMethodSelectItemsQuery";
import { listMailTemplatesQuery } from "./admin/queries/listMailTemplatesQuery";
import { getMailTemplateByIdQuery } from "./admin/queries/getMailTemplateByIdQuery";
import { listPagesQuery } from "./admin/queries/listPagesQuery";
import { getPageByIdQuery } from "./admin/queries/getPageByIdQuery";
import { listShippingMethodsQuery } from "./admin/queries/listShippingMethodsQuery";
import { getShippingMethodByIdQuery } from "./admin/queries/getShippingMethodByIdQuery";
import { listOrdersQuery } from "./admin/queries/listOrdersQuery";
import { listSubscriptionsQuery } from "./admin/queries/listSubscriptionsQuery";
import { listGiftCardOrdersQuery } from "./admin/queries/listGiftCardOrdersQuery";
import { listStoreOrdersQuery } from "./admin/queries/listStoreOrdersQuery";
import { listPaymentStatusSelectItemsQuery } from "./admin/queries/listPaymentStatusSelectItemsQuery";
import { getOrderByIdQuery } from "./admin/queries/getOrderByIdQuery";
import { giftCardOrderQuery } from "./admin/queries/giftCardOrderQuery";
import { giftCardOrderGiftCardsQuery } from "./admin/queries/giftCardOrderGiftCardsQuery";
import { listWebsiteNotificationsQuery } from "./admin/queries/listWebsiteNotificationsQuery";
import { getWebsiteNotificationByIdQuery } from "./admin/queries/getWebsiteNotificationByIdQuery";
import { listSitesQuery } from "./admin/queries/listSitesQuery";
import { storeAdminPrintOrdersQuery } from "./admin/queries/storeAdminPrintOrdersQuery";
import { listStoresForSelectQuery } from "./admin/queries/listStoresForSelectQuery";
import { listShipmentStatuses } from "./admin/queries/listShipmentStatusesQuery";
import { printOrderQuery } from "./admin/queries/printOrderQuery";
import { listSystemSettingsQuery } from "./admin/queries/listSystemSettingsQuery";
import { listPaymentMethodsQuery } from "./admin/queries/listPaymentMethodsQuery";
import { getPaymentMethodByIdQuery } from "./admin/queries/getPaymentMethodByIdQuery";
import { listTimeWindowIntervalSelectItemsQuery } from "./admin/queries/listTimeWindowIntervalSelectItemsQuery";
import { listShipmentStatusSelectItemsQuery } from "./admin/queries/listShipmentStatusSelectItemsQuery";
import { listCalendarDaysQuery } from "./admin/queries/listCalendarDaysQuery";
import { productParamListQuery } from "./admin/queries/productParamListQuery";
import { productParamQuery } from "./admin/queries/productParamQuery";
import { exportOrderQuery } from "./admin/queries/exportOrderQuery";
import { listOrdersExportQuery } from "./admin/queries/listOrdersExportQuery";
import { listGiftCardsExportQuery } from "./admin/queries/listGiftCardsExportQuery";
import { modelUpdateLogQuery } from "./admin/queries/modelUpdateLogQuery";
import { listMaintenancesQuery } from "./admin/queries/listMaintenancesQuery";
import { getMaintenanceByIdQuery } from "./admin/queries/getMaintenanceByIdQuery";
import { sessionQuery } from "./auth/queries/sessionQuery";
import { listTodosQuery } from "./admin/queries/listTodosQuery";
import { getTodoByIdQuery } from "./admin/queries/getTodoByIdQuery";
import { listAdminsForSelectQuery } from "./admin/queries/listAdminsForSelectQuery";
import { listMenusQuery } from "./admin/queries/listMenusQuery";
import { getMenuByIdQuery } from "./admin/queries/getMenuByIdQuery";
import { listProductCategoriesForMenuQuery } from "./admin/queries/listProductCategoriesForMenuQuery";
import { listPagesForMenuQuery } from "./admin/queries/listPagesForMenuQuery";
import { listPagesForSelectQuery } from "./admin/queries/listPagesForSelectQuery";
import { listProductsForMenuQuery } from "./admin/queries/listProductsForMenuQuery";
import { listProductListsForMenuQuery } from "./admin/queries/listProductListsForMenuQuery";
import { listProductBrandsForMenuQuery } from "./admin/queries/listProductBrandsForMenuQuery";
import { listAdminContentsForMenuQuery } from "./admin/queries/listAdminContentsForMenuQuery";
import { listProductBundlesQuery } from "./admin/queries/listProductBundlesQuery";
import { listProductVariantsQuery } from "./admin/queries/listProductVariantsQuery";
import { getProductVariantByIdQuery } from "./admin/queries/getProductVariantByIdQuery";
import { listRatingsQuery } from "./admin/queries/listRatingsQuery";
import { listFulfillmentQuery } from "./admin/queries/listFulfillmentQuery";
import { getFulfillmentByIdQuery } from "./admin/queries/getFulfillmentByIdQuery";
import { indexStatusQuery } from "./admin/queries/indexStatusQuery";
import { listCampaignsQuery } from "./admin/queries/listCampaignsQuery";
import { getCampaignByIdQuery } from "./admin/queries/getCampaignByIdQuery";
import { listProductGratisQuery } from "./admin/queries/listProductGratisQuery";
import { getProductCategoryChildrenQuery } from "./admin/queries/getProductCategoryChildrenQuery";
import { getSubscriptionByIdQuery } from "./admin/queries/getSubscriptionByIdQuery";
import { getCsrfTokenQuery } from "./auth/queries/getCsrfTokenQuery";

export const Queries = {
    adminMe: adminMeQuery,
    adminContents: adminContentsQuery,
    listAdmins: listAdminsQuery,
    getAdmin: getAdminQuery,
    getAdminContent: getAdminContentQuery,
    getCategoriesForSelect: getCategoriesForSelectQuery,
    getDocumentsForModal: getDocumentsForModalQuery,
    documents: documentsQuery,
    categories: categoriesQuery,
    getCategory: getCategoryQuery,
    galleries: galleriesQuery,
    getGallery: getGalleryQuery,
    getGalleryImages: getGalleryImagesQuery,
    listProductBrands: listProductBrandsQuery,
    getProductBrandById: getProductBrandByIdQuery,
    listProducts: listProductsQuery,
    listProductCategoriesById: listProductCategoriesByIdQuery,
    getProductCategoryChildren: getProductCategoryChildrenQuery,
    listProductCategorySelectItems: listProductCategorySelectItemsQuery,
    getProductById: getProductByIdQuery,
    listProductParamStructures: listProductParamStructuresQuery,
    listProductLists: listProductListsQuery,
    listBadges: listBadgesQuery,
    listBadgeById: listBadgeByIdQuery,
    listProductParamSelectItems: listProductParamSelectItemsQuery,
    getProductListById: getProductListByIdQuery,
    listRoles: listRolesQuery,
    getRoleById: getRoleByIdQuery,
    listPermissions: listPermissionsQuery,
    listProductFilterParams: listProductFilterParamsQuery,
    listClpePromotionSelectItems: listClpePromotionSelectItemsQuery,
    listProductBrandSelectItems: listProductBrandSelectItemsQuery,
    listBanners: listBannersQuery,
    getBannerById: getBannerByIdQuery,
    listUsers: listUsersQuery,
    getUserById: getUserByIdQuery,
    listUserOrders: listUserOrdersQuery,
    getPerson: getPersonQuery,
    persons: personsQuery,
    getBenefit: getBenefitQuery,
    benefits: benefitsQuery,
    getDepartment: getDepartmentQuery,
    departments: departmentsQuery,
    getDivision: getDivisionQuery,
    divisions: divisionsQuery,
    getSuccessStory: getSuccessStoryQuery,
    successStories: successStoriesQuery,
    redirections: redirectionsQuery,
    getRedirection: getRedirectionQuery,
    listShippingMethodSelectItems: listShippingMethodSelectItemsQuery,
    listPaymentMethodSelectItems: listPaymentMethodSelectItemsQuery,
    listMailTemplates: listMailTemplatesQuery,
    getMailTemplateById: getMailTemplateByIdQuery,
    listPages: listPagesQuery,
    getPageById: getPageByIdQuery,
    listShippingMethods: listShippingMethodsQuery,
    getShippingMethodById: getShippingMethodByIdQuery,
    listOrders: listOrdersQuery,
    listSubscriptions: listSubscriptionsQuery,
    listGiftCardOrders: listGiftCardOrdersQuery,
    listStoreOrders: listStoreOrdersQuery,
    listPaymentStatusSelectItems: listPaymentStatusSelectItemsQuery,
    getOrderById: getOrderByIdQuery,
    getSubscriptionById: getSubscriptionByIdQuery,
    giftCardOrder: giftCardOrderQuery,
    giftCardOrderGiftCards: giftCardOrderGiftCardsQuery,
    listWebsiteNotifications: listWebsiteNotificationsQuery,
    getWebsiteNotificationById: getWebsiteNotificationByIdQuery,
    listSites: listSitesQuery,
    storeAdminPrintOrders: storeAdminPrintOrdersQuery,
    listStoresForSelect: listStoresForSelectQuery,
    listShipmentStatuses: listShipmentStatuses,
    printOrder: printOrderQuery,
    listSystemSettings: listSystemSettingsQuery,
    listPaymentMethods: listPaymentMethodsQuery,
    getPaymentMethodById: getPaymentMethodByIdQuery,
    listTimeWindowIntervalSelectItems: listTimeWindowIntervalSelectItemsQuery,
    listShipmentStatusSelectItems: listShipmentStatusSelectItemsQuery,
    listCalendarDays: listCalendarDaysQuery,
    listProductParams: productParamListQuery,
    productParam: productParamQuery,
    exportOrder: exportOrderQuery,
    listOrdersExport: listOrdersExportQuery,
    listGiftCardsExport: listGiftCardsExportQuery,
    modelUpdateLog: modelUpdateLogQuery,
    listMaintenances: listMaintenancesQuery,
    getMaintenanceById: getMaintenanceByIdQuery,
    session: sessionQuery,
    listTodos: listTodosQuery,
    getTodoById: getTodoByIdQuery,
    listAdminsForSelect: listAdminsForSelectQuery,
    listMenus: listMenusQuery,
    getMenuById: getMenuByIdQuery,
    listProductCategoriesForMenu: listProductCategoriesForMenuQuery,
    listPagesForMenu: listPagesForMenuQuery,
    listPagesForSelect: listPagesForSelectQuery,
    listProductsForMenu: listProductsForMenuQuery,
    listProductListsForMenu: listProductListsForMenuQuery,
    listProductBrandsForMenu: listProductBrandsForMenuQuery,
    listAdminContentsForMenu: listAdminContentsForMenuQuery,
    listProductBundles: listProductBundlesQuery,
    listProductVariants: listProductVariantsQuery,
    getProductVariantById: getProductVariantByIdQuery,
    listRatings: listRatingsQuery,
    listFulfillment: listFulfillmentQuery,
    getFulfillmentById: getFulfillmentByIdQuery,
    indexStatus: indexStatusQuery,
    listCampaigns: listCampaignsQuery,
    getCampaignById: getCampaignByIdQuery,
    listProductGratis: listProductGratisQuery,
    getCsrfToken: getCsrfTokenQuery,
};
