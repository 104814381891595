import React, { useMemo } from "react";
import { Grid, Typography, Box } from "@bigfish/admin-ui/core";
import { GiftCard, GiftCardOrder } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { PageCard, TabsComponent, TitleBar } from "@bigfish/admin-ui/components";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import "./styles.css";
import LogListDataGrid from "Pages/LogList/LogListDataGrid";
import GiftCardOrderItemListDataGrid from "./GiftCardOrderItemListDataGrid";
import GiftCardListDataGrid from "./GiftCardListDataGrid";

export type DisplayedContainer = {
    id: number;
    title: string;
};

type Props = {
    giftCardOrder?: GiftCardOrder;
};

export const GiftCardOrderInfo = (props: Props) => {
    const [tab, setTab] = React.useState<number>(0);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const renderItemsListDataGrid = useMemo(() => {
        return props.giftCardOrder?.items ? <GiftCardOrderItemListDataGrid items={props.giftCardOrder.items} /> : null;
    }, [props.giftCardOrder?.items]);

    const renderGiftCardListDataGrid = useMemo(() => {
        return props.giftCardOrder?.gift_cards ? (
            <GiftCardListDataGrid
                orderId={props.giftCardOrder.id}
                giftCards={props.giftCardOrder.gift_cards.length > 0 ? (props.giftCardOrder.gift_cards.filter(gc => gc !== null) as GiftCard[]) : []}
            />
        ) : null;
    }, [props.giftCardOrder?.gift_cards]);

    const renderField = (i18nLabelId: string, value: JSX.Element | string | null) => {
        return (
            <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: `pages.giftCardOrder.info.label.${i18nLabelId}` })}
                </Typography>
                <Typography variant="subtitle1">{value}</Typography>
            </div>
        );
    };

    const renderBasicDataTab = (): JSX.Element | null => {
        if (!props.giftCardOrder) return null;

        const { billing_address } = props.giftCardOrder;

        return (
            <div>
                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("id", `${props.giftCardOrder.id}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("code", props.giftCardOrder.code)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("created_at", `${DateUtils.format(DateUtils.parseISO(props.giftCardOrder.created_at), DateFormat.yyyymmddhhmm)}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("userName", `${props.giftCardOrder.contact_fullname ?? "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("email", `${props.giftCardOrder.email || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("phoneNumber", `${props.giftCardOrder.phone_number}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("status", `${props.giftCardOrder.status.title}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField(
                            "billingAddress",
                            billing_address ? (
                                <div>
                                    {(billing_address.lastname || billing_address.firstname) && (
                                        <div>{[billing_address.lastname || "", billing_address.firstname || ""].join(" ")}</div>
                                    )}
                                    <div>
                                        {billing_address.zip_code || billing_address.city || billing_address.address_line1 || billing_address.address_line2
                                            ? [billing_address.zip_code, billing_address.city, billing_address.address_line1, billing_address.address_line2]
                                                  .filter(address => address !== null && address !== "")
                                                  .join(" ")
                                            : "-"}
                                    </div>
                                    {billing_address.company_name && <div>{billing_address.company_name}</div>}
                                    {billing_address.company_number && <div>{billing_address.company_number}</div>}
                                </div>
                            ) : null
                        )}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("paymentMethodName", `${props.giftCardOrder.payment_method.name || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("grand_total", `${props.giftCardOrder.grand_total ? I18n.formatCurrency(props.giftCardOrder.grand_total) : "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("amountRefunded", `${props.giftCardOrder.payment?.amount_refunded ? I18n.formatCurrency(props.giftCardOrder.payment.amount_refunded) : "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("paymentStatus", `${props.giftCardOrder.payment?.status.title || "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("invoiceStatus", `${props.giftCardOrder.invoice_status?.title || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("grand_total", `${props.giftCardOrder.grand_total ? I18n.formatCurrency(props.giftCardOrder.grand_total) : "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("transactionId", `${props.giftCardOrder.payment?.transaction_latest?.id || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("anum", `${props.giftCardOrder.payment?.transaction_latest?.anum || "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.order.form.label.downloadInvoices" })}
                        </Typography>
                        <Typography variant="subtitle1">
                            {props.giftCardOrder.invoices && props.giftCardOrder.invoices.length > 0
                                ? props.giftCardOrder.invoices.map(i => {
                                      return i.url ? (
                                          <div>
                                              <a key={i.invoice_number} href={i.url} target="_blank" rel="noreferrer">
                                                  {i.invoice_number}
                                              </a>
                                          </div>
                                      ) : null;
                                  })
                                : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("user_agent_type", `${props.giftCardOrder.user_agent_type}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />
            </div>
        );
    };

    const renderItemsTab = (): JSX.Element | null => {
        const { giftCardOrder } = props;

        if (!giftCardOrder) return null;

        return (
            <div>
                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        {renderField("orderedItems", `${giftCardOrder.items.length}`)}
                    </Grid>
                    <Grid item xs={4}>
                        {renderField(
                            "orderedProducts",
                            `${giftCardOrder.items
                                .map(i => i.quantity)
                                .reduce((total, num) => {
                                    return total + num;
                                })}`
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {renderField("grand_total", I18n.formatCurrency(giftCardOrder.grand_total))}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                {renderItemsListDataGrid}
            </div>
        );
    };

    return (
        <div>
            {props.giftCardOrder?.id && <TitleBar title={I18n.formatMessage({ id: "pages.giftCardOrder.info.title" }, { id: props.giftCardOrder.id })} />}

            <PageCard.Container>
                <TabsComponent
                    ariaLabel="gift-card-order-form-tabs"
                    tabs={[
                        {
                            label: I18n.formatMessage({ id: "pages.giftCardOrder.info.tabs.data" }),
                            id: "gift-card-data-tab",
                            content: renderBasicDataTab(),
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.giftCardOrder.info.tabs.items" }),
                            id: "gift-card-items-tab",
                            content: renderItemsTab(),
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.giftCardOrder.info.tabs.giftCards" }),
                            id: "gift-card-gift-cards-tab",
                            content: <>{renderGiftCardListDataGrid}</>,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.giftCardOrder.info.tabs.log" }),
                            id: "gift-card-log-tab",
                            content: (
                                <Box mt="30px">
                                    <LogListDataGrid incrementId={props.giftCardOrder?.id} />
                                </Box>
                            ),
                        },
                    ]}
                    currentTab={tab}
                    changeTab={changeTab}
                />
            </PageCard.Container>
        </div>
    );
};
