/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminLogin
// ====================================================

export interface adminLogin_login_admin_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface adminLogin_login_admin_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: adminLogin_login_admin_roles_permissions[];
}

export interface adminLogin_login_admin_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface adminLogin_login_admin {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: adminLogin_login_admin_roles[];
  store: adminLogin_login_admin_store | null;
}

export interface adminLogin_login {
  __typename: "AdminAuthenticationResult";
  token: string;
  admin: adminLogin_login_admin;
  media_library_token: string | null;
}

export interface adminLogin {
  login: adminLogin_login;
}

export interface adminLoginVariables {
  credentials: Login;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminLogout
// ====================================================

export interface adminLogout {
  adminLogout: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminPasswordReset
// ====================================================

export interface adminPasswordReset {
  passwordReset: boolean;
}

export interface adminPasswordResetVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetAdminPassword
// ====================================================

export interface resetAdminPassword_resetPassword_admin_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface resetAdminPassword_resetPassword_admin_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: resetAdminPassword_resetPassword_admin_roles_permissions[];
}

export interface resetAdminPassword_resetPassword_admin_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface resetAdminPassword_resetPassword_admin {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: resetAdminPassword_resetPassword_admin_roles[];
  store: resetAdminPassword_resetPassword_admin_store | null;
}

export interface resetAdminPassword_resetPassword {
  __typename: "AdminAuthenticationResult";
  token: string;
  admin: resetAdminPassword_resetPassword_admin;
}

export interface resetAdminPassword {
  resetPassword: resetAdminPassword_resetPassword;
}

export interface resetAdminPasswordVariables {
  input: SetAdminPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCsrfToken
// ====================================================

export interface getCsrfToken {
  csrfToken: string;
}

export interface getCsrfTokenVariables {
  mutation: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: session
// ====================================================

export interface session_session {
  __typename: "AdminAuthenticationResult";
  token: string;
}

export interface session {
  session: session_session;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Admin
// ====================================================

export interface Admin_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface Admin_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: Admin_roles_permissions[];
}

export interface Admin_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface Admin {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: Admin_roles[];
  store: Admin_store | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleListItem
// ====================================================

export interface RoleListItem {
  __typename: "Role";
  id: number;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PermissionType {
  admin_read = "admin_read",
  admin_todo_read = "admin_todo_read",
  admin_todo_update = "admin_todo_update",
  admin_update = "admin_update",
  badge_read = "badge_read",
  badge_update = "badge_update",
  banner_read = "banner_read",
  banner_update = "banner_update",
  content_read = "content_read",
  content_update = "content_update",
  debug = "debug",
  gift_card_order_create = "gift_card_order_create",
  gift_card_order_update = "gift_card_order_update",
  gift_card_resend = "gift_card_resend",
  invoice_update = "invoice_update",
  log_read = "log_read",
  mail_template_read = "mail_template_read",
  mail_template_update = "mail_template_update",
  maintenance_create = "maintenance_create",
  maintenance_delete = "maintenance_delete",
  maintenance_read = "maintenance_read",
  maintenance_update = "maintenance_update",
  manage_cache = "manage_cache",
  manage_calendar = "manage_calendar",
  manage_career_benefits = "manage_career_benefits",
  manage_career_departments = "manage_career_departments",
  manage_career_divisions = "manage_career_divisions",
  manage_career_persons = "manage_career_persons",
  manage_career_success_stories = "manage_career_success_stories",
  manage_promotions = "manage_promotions",
  manage_redirections = "manage_redirections",
  manage_system = "manage_system",
  menu_read = "menu_read",
  menu_update = "menu_update",
  order_close = "order_close",
  order_delete = "order_delete",
  order_read = "order_read",
  order_refund = "order_refund",
  order_storno = "order_storno",
  order_update = "order_update",
  payment_method_read = "payment_method_read",
  payment_method_update = "payment_method_update",
  product_brand_read = "product_brand_read",
  product_brand_update = "product_brand_update",
  product_bundle_create = "product_bundle_create",
  product_bundle_update = "product_bundle_update",
  product_list_create = "product_list_create",
  product_list_delete = "product_list_delete",
  product_list_read = "product_list_read",
  product_list_update = "product_list_update",
  product_param_read = "product_param_read",
  product_param_update = "product_param_update",
  product_read = "product_read",
  product_update = "product_update",
  product_variant_create = "product_variant_create",
  product_variant_delete = "product_variant_delete",
  product_variant_update = "product_variant_update",
  publication_read = "publication_read",
  publication_update = "publication_update",
  role_read = "role_read",
  role_update = "role_update",
  setting_read = "setting_read",
  setting_update = "setting_update",
  shipment_update = "shipment_update",
  shipping_method_read = "shipping_method_read",
  shipping_method_update = "shipping_method_update",
  store_read = "store_read",
  store_update = "store_update",
  user_add_coupon = "user_add_coupon",
  user_delete = "user_delete",
  user_login = "user_login",
  user_read = "user_read",
  user_update = "user_update",
  website_notification_create = "website_notification_create",
  website_notification_read = "website_notification_read",
  website_notification_update = "website_notification_update",
}

export interface Login {
  login: string;
  password: string;
}

export interface SetAdminPasswordInput {
  token: string;
  email: string;
  password: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
