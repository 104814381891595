export class Path {
    public static readonly login = "/login";
    public static readonly logout = "/logout";
    public static readonly dashboard = "/";
    public static readonly forgotPassword = "/forgot-password";
    public static readonly myProfile = "/my-profile";

    /**
     * @param data string in email:token format
     */
    public static resetPassword(data: string) {
        return `/reset-password/${data}`;
    }

    public static emailActivation(email: string, token: string) {
        return `/activate/${email}/${token}`;
    }

    public static readonly mediaLibrary = "/media-library";
    public static readonly siteList = "/sites";

    public static readonly users = "/users";
    public static readonly clientList = "/users/clients";

    public static readonly contentList = "/contents";
    public static readonly contentCreate = `${Path.contentList}/create`;
    public static contentEdit(id: string) {
        return `${Path.contentList}/${id}`;
    }

    private static readonly usersPrefix = "/users";

    public static readonly adminList = `${Path.usersPrefix}/admins`;
    public static readonly adminCreate = `${Path.adminList}/create`;
    public static adminEdit(id: number | string): string {
        return `${Path.adminList}/${id}`;
    }

    public static readonly roleList = `${Path.usersPrefix}/roles`;
    public static readonly roleCreate = `${Path.roleList}/create`;
    public static roleEdit(id: number | string): string {
        return `${Path.roleList}/${id}`;
    }

    public static readonly documentList = "/documents";

    public static readonly categoryList = "/categories";
    public static readonly categoryCreate = `${Path.categoryList}/create`;
    public static categoryEdit(categoryId: string): string {
        return `${Path.categoryList}/${categoryId}`;
    }

    public static readonly galleryList = "/galleries";
    public static readonly galleryCreate = `${Path.galleryList}/create`;
    public static galleryEdit(id: string) {
        return `${Path.galleryList}/${id}`;
    }

    private static readonly productsPrefix = "/products";

    public static readonly productCategoryList = `${Path.productsPrefix}/categories`;
    public static productCategoryEdit(id: string) {
        return `${Path.productCategoryList}/${id}`;
    }

    public static readonly productCatalog = `${Path.productsPrefix}/products`;
    public static productEdit(id: string) {
        return `${Path.productCatalog}/${id}`;
    }

    public static readonly productBrandList = `${Path.productsPrefix}/brands`;
    public static productBrandEdit(id: string): string {
        return `${Path.productBrandList}/${id}`;
    }

    public static readonly productListList = `${Path.productsPrefix}/lists`;
    public static productListCreate(productListType: string): string {
        return `${Path.productListList}/create/${productListType}`;
    }
    public static productListEdit(id: string) {
        return `${Path.productListList}/${id}`;
    }
    public static readonly productBundles = `${Path.productsPrefix}/bundles`;
    public static readonly productBundleCreate = `${Path.productsPrefix}/bundles/create`;
    public static readonly productBundleEdit = (id: string) => `${Path.productBundles}/${id}`;

    public static readonly productGratisList = `${Path.productsPrefix}/gratis`;
    public static readonly productGratisEdit = (id: string) => `${Path.productGratisList}/${id}`;

    private static readonly contentsPrefix = "/content-editor";

    public static readonly badgeList = `${Path.contentsPrefix}/badges`;
    public static readonly badgeCreate = `${Path.badgeList}/create`;
    public static badgeEdit(id: string) {
        return `${Path.badgeList}/${id}`;
    }

    public static readonly bannerList = `${Path.contentsPrefix}/banners`;
    public static bannerCreate(type: string): string {
        return `${Path.bannerList}/create/${type}`;
    }
    public static bannerEdit(id: string) {
        return `${Path.bannerList}/${id}`;
    }

    public static readonly userList = `${Path.usersPrefix}/users`;
    public static userEdit(id: number | string): string {
        return `${Path.userList}/${id}`;
    }

    public static readonly jobs = "/jobs";

    public static readonly personList = `${Path.jobs}/persons`;
    public static readonly personCreate = `${Path.personList}/create`;
    public static personEdit(id: string) {
        return `${Path.personList}/${id}`;
    }

    public static readonly benefitList = `${Path.jobs}/benefits`;
    public static readonly benefitCreate = `${Path.benefitList}/create`;
    public static benefitEdit(id: string) {
        return `${Path.benefitList}/${id}`;
    }

    public static readonly departmentList = `${Path.jobs}/departments`;
    public static readonly departmentCreate = `${Path.departmentList}/create`;
    public static departmentEdit(id: string) {
        return `${Path.departmentList}/${id}`;
    }

    public static readonly divisionList = `${Path.jobs}/divisions`;
    public static readonly divisionCreate = `${Path.divisionList}/create`;
    public static divisionEdit(id: string) {
        return `${Path.divisionList}/${id}`;
    }

    public static readonly successStoryList = `${Path.jobs}/successStories`;
    public static readonly successStoryCreate = `${Path.successStoryList}/create`;
    public static successStoryEdit(id: string) {
        return `${Path.successStoryList}/${id}`;
    }

    public static readonly redirectionList = "/redirections";
    public static redirectionCreate(type: string): string {
        return `${Path.redirectionList}/create/${type}`;
    }
    public static redirectionEdit(id: string) {
        return `${Path.redirectionList}/${id}`;
    }

    private static settingsPrefix = "/settings";

    public static mailTemplateList = `${Path.settingsPrefix}/mail-templates`;
    public static mailTemplateEdit(id: number | string): string {
        return `${Path.mailTemplateList}/${id}`;
    }

    public static pageList = `${Path.contentsPrefix}/pages`;
    public static pageCreate = `${Path.pageList}/create`;
    public static pageEdit(id: string) {
        return `${Path.pageList}/${id}`;
    }

    private static readonly shippingPrefix = "/shipping";

    public static readonly fulfillmentList = `${Path.settingsPrefix}/fulfillments`;
    public static fulfillmentCreate = `${Path.fulfillmentList}/create`;
    public static fulfillmentEdit(id: string) {
        return `${Path.fulfillmentList}/${id}`;
    }

    public static readonly shippingMethodList = `${Path.shippingPrefix}/methods`;
    public static shippingMethodEdit(id: string) {
        return `${Path.shippingMethodList}/${id}`;
    }

    public static readonly orderList = "/orders";
    public static orderEdit(incrementId: string) {
        return `${Path.orderList}/${incrementId}`;
    }

    public static readonly subscriptionList = "/subscriptions";
    public static subscriptionEdit(id: string) {
        return `${Path.subscriptionList}/${id}`;
    }

    public static readonly giftCardOrderList = "/gift-card-orders";
    public static giftCardOrderEdit(id: string) {
        return `${Path.giftCardOrderList}/${id}`;
    }

    public static readonly storeOrderList = "/store-orders";

    public static readonly websiteNotificationList = `${Path.contentsPrefix}/website-notifications`;
    public static readonly websiteNotificationCreate = `${Path.websiteNotificationList}/create`;
    public static websiteNotificationEdit(id: string) {
        return `${Path.websiteNotificationList}/${id}`;
    }

    public static readonly calendarDaysList = "/calendar-days";
    public static calendarDayCreate = `${Path.calendarDaysList}/create`;
    public static calendarDayEdit(date: string): string {
        return `${Path.calendarDaysList}/${date}`;
    }

    public static systemSettingsList = `${Path.settingsPrefix}/system-settings`;

    public static maintenanceList = `${Path.settingsPrefix}/maintenances`;
    public static maintenanceCreate = `${Path.maintenanceList}/create`;
    public static maintenanceEdit(id: string): string {
        return `${Path.maintenanceList}/${id}`;
    }

    private static readonly paymentPrefix = "/payment";
    public static readonly paymentMethodList = `${Path.paymentPrefix}/methods`;
    public static paymentMethodEdit(id: string) {
        return `${Path.paymentMethodList}/${id}`;
    }
    public static productParamList = `${Path.productsPrefix}/product-params`;
    public static productParamEdit(id: string) {
        return `${Path.productParamList}/${id}`;
    }

    public static readonly menuList = `${Path.contentsPrefix}/menus`;
    public static readonly menuCreate = `${Path.menuList}/create`;
    public static menuEdit(id: string) {
        return `${Path.menuList}/${id}`;
    }

    public static logList = "/logs";

    public static todoList = "/todos";
    public static todoEdit(id: string) {
        return `${Path.todoList}/${id}`;
    }

    public static productVariantList = `${Path.productsPrefix}/product-variants`;
    public static productVariantCreate = `${Path.productVariantList}/create`;
    public static productVariantEdit(id: string) {
        return `${Path.productVariantList}/${id}`;
    }

    public static cacheManagement = `${Path.settingsPrefix}/cache-management`;

    public static readonly campaignList = `${Path.contentsPrefix}/campaigns`;
    public static readonly campaignCreate = `${Path.campaignList}/create`;
    public static campaignEdit(id: string) {
        return `${Path.campaignList}/${id}`;
    }

    public static ratingList = "/ratings";
}
